import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.scss";
import Dashboard from "./Components/Dashboard/Dashboard";
import WithModal from "./Components/Dashboard/WithModal";

const Signin = lazy(() => import("./Components/Signin"));
const Signup = lazy(() => import("./Components/Signup"));
// const TinyMCE = lazy(() => import('./Components/_common/TinyMCE/TinyMCE'));
//Edit
const EditCommon = lazy(() => import("./Components/Edit/EditCommon"));
const EditAgency = lazy(() => import("./Components/Edit/EditAgency"));
const EditArticle1 = lazy(() => import("./Components/Edit/EditArticleStep1"));
const EditArticleBody = lazy(
  () => import("./Components/Edit/EditArticleStep2")
);
//Articles
const Articles = lazy(() => import("./Components/Articles/Articles"));
const AddArticles = lazy(() => import("./Components/Articles/AddArticles"));
const AddArticleBody = lazy(
  () => import("./Components/Articles/AddArticleBody")
);
const ViewArticle = lazy(() => import("./Components/Articles/ViewArticle"));
//Category
const Category = lazy(() => import("./Components/Category/Category"));
const AddCategory = lazy(() => import("./Components/Category/Addcategory"));
//Products
const Products = lazy(() => import("./Components/Products/Products"));
const AddProducts = lazy(() => import("./Components/Products/AddProducts"));
const EditProduct = lazy(() => import("./Components/Products/EditProduct"));
//Places
const Places = lazy(() => import("./Components/Places/Places"));
const AddPlaces = lazy(() => import("./Components/Places/AddPlace"));
//Hotels
const Hotels = lazy(() => import("./Components/Hotels/Hotels"));
const AddHotels = lazy(() => import("./Components/Hotels/AddHotels"));
//Hotels
const Agency = lazy(() => import("./Components/TravelAgency/Agency"));
const AddAgency = lazy(() => import("./Components/TravelAgency/AddAgency"));
const EditHotel = lazy(() => import("./Components/Edit/EditHotel"));
//Images
const Images = lazy(() => import("./Components/Image/Images"));

const AddGallery = lazy(() => import("./Components/Gallery/AddGallery"));
const Gallery = lazy(() => import("./Components/Gallery/Gallery"));

const App: React.FC = () => {
  const Dashboard1 = (props: any, component: any) => (
    <Dashboard component={component} {...props} />
  );
  const WithModal1 = (props: any, component: any) => (
    <WithModal component={component} {...props} />
  );
  useEffect(() => {
    (window as any).process = {
      ...window.process,
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/signup/n0q5GRvoMjtVO64A" component={Signup} exact />
            <Route path="/" component={Signin} exact />
            <Route
              exact
              path="/addarticles"
              component={(props: any) => Dashboard1({ ...props }, AddArticles)}
            />
            <Route
              exact
              path="/viewarticle/:id"
              component={(props: any) => Dashboard1({ ...props }, ViewArticle)}
            />
            <Route
              exact
              path="/addarticlebody/:id"
              component={(props: any) =>
                Dashboard1({ ...props }, AddArticleBody)
              }
            />
            <Route
              exact
              path="/articles"
              component={(props: any) => Dashboard1({ ...props }, Articles)}
            />
            <Route
              exact
              path="/images"
              component={(props: any) => Dashboard1({ ...props }, Images)}
            />
            <Route
              exact
              path="/products"
              component={(props: any) => Dashboard1({ ...props }, Products)}
            />
            <Route
              exact
              path="/addproducts"
              component={(props: any) => Dashboard1({ ...props }, AddProducts)}
            />
            <Route
              exact
              path="/editproduct:id"
              component={(props: any) => Dashboard1({ ...props }, EditProduct)}
            />
            <Route
              exact
              path="/addcategory"
              component={(props: any) => WithModal1({ ...props }, AddCategory)}
            />
            <Route
              exact
              path="/category"
              component={(props: any) => Dashboard1({ ...props }, Category)}
            />
            <Route
              exact
              path="/places"
              component={(props: any) => Dashboard1({ ...props }, Places)}
            />
            <Route
              exact
              path="/addplaces"
              component={(props: any) => Dashboard1({ ...props }, AddPlaces)}
            />
            <Route
              exact
              path="/hotels"
              component={(props: any) => Dashboard1({ ...props }, Hotels)}
            />
            <Route
              exact
              path="/addhotels"
              component={(props: any) => Dashboard1({ ...props }, AddHotels)}
            />
            <Route
              exact
              path="/agency"
              component={(props: any) => Dashboard1({ ...props }, Agency)}
            />
            <Route
              exact
              path="/addagency"
              component={(props: any) => Dashboard1({ ...props }, AddAgency)}
            />
            {/* Edit */}
            <Route
              exact
              path="/edit/:type/:id"
              component={(props: any) => Dashboard1({ ...props }, EditCommon)}
            />

            <Route
              exact
              path="/editagency/:id"
              component={(props: any) => Dashboard1({ ...props }, EditAgency)}
            />
            <Route
              exact
              path="/editarticle1/:id"
              component={(props: any) => Dashboard1({ ...props }, EditArticle1)}
            />
            <Route
              exact
              path="/editarticlebody/:id"
              component={(props: any) =>
                Dashboard1({ ...props }, EditArticleBody)
              }
            />
            <Route
              exact
              path="/edithotel/:id"
              component={(props: any) => Dashboard1({ ...props }, EditHotel)}
            />
            <Route
              exact
              path="/addgallery"
              component={(props: any) => Dashboard1({ ...props }, AddGallery)}
            />
            <Route
              exact
              path="/gallery"
              component={(props: any) => Dashboard1({ ...props }, Gallery)}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
