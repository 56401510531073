import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      borderRadius: "10px",
      backgroundColor: theme.palette.background.paper,
      border: "none",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

// interface FadeProps {
//   children?: React.ReactElement;
//   in: boolean;
//   onEnter?: () => {};
//   onExited?: () => {};
// }

// const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
//   props,
//   ref
// ) {
// const { in: open, children, onEnter, onExited } = props;
// const style = useSpring({
//   from: { opacity: 0 },
//   to: { opacity: open ? 1 : 0 },
//   onStart: () => {
//     if (open && onEnter) {
//       onEnter();
//     }
//   },
//   onRest: () => {
//     if (!open && onExited) {
//       onExited();
//     }
//   },
// });

//   return <div>{children}</div>;
// });

export default function WithModal({ component: Component, ...props }: any) {
  const classes = useStyles();
  const MyModal: any = Modal;
  return (
    <MyModal
      // className="Modal"
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={true}
      onClose={() => props.history.goBack()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      {/* <Fade in={true}> */}
      <div className={classes.paper}>
        <Component {...props} />
      </div>
      {/* </Fade> */}
    </MyModal>
  );
}
