import React, { Suspense } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Divider, Drawer, Hidden, IconButton, List } from "@material-ui/core";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Toolbar, Typography } from "@material-ui/core";
import { FolderOpen, Category, Business } from "@material-ui/icons";
import { AddCircleOutline } from "@material-ui/icons";
import { ViewAgenda, DoubleArrow, Collections, Menu } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./DashboardData";
import "./Dashboard.scss";
import { Link } from "react-router-dom";

export default function ResponsiveDrawer({
  container,
  component: Component,
  ...props
}: any): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const MyHidden: any = Hidden;

  const drawer = (
    <div className="DrawerMain">
      <div className={classes.toolbar} />
      <Divider />
      <List className="menu">
        <Link className="link" to="/articles">
          <ListItem>
            <ListItemIcon>
              <FolderOpen style={{ color: "#2196c4" }} />
            </ListItemIcon>
            <ListItemText>Articles</ListItemText>
          </ListItem>
        </Link>
        <Link className="link" to="/addarticles">
          <ListItem>
            <ListItemIcon>
              <AddCircleOutline style={{ color: "#2196c4" }} />
            </ListItemIcon>
            <ListItemText>Add Articles</ListItemText>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className="menu">
        <Link className="link" to="/products">
          <ListItem>
            <ListItemIcon>
              <ViewAgenda style={{ color: "grey" }} />
            </ListItemIcon>
            <ListItemText>Products</ListItemText>
          </ListItem>
        </Link>
        <Link className="link" to="/addproducts">
          <ListItem>
            <ListItemIcon>
              <AddCircleOutline style={{ color: "grey" }} />
            </ListItemIcon>
            <ListItemText>Add Products</ListItemText>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className="menu">
        <Link className="link" to="/places">
          <ListItem>
            <ListItemIcon>
              <Collections style={{ color: "#bf80ff" }} />
            </ListItemIcon>
            <ListItemText>Places</ListItemText>
          </ListItem>
        </Link>
        <Link className="link" to="/addplaces">
          <ListItem>
            <ListItemIcon>
              <AddCircleOutline style={{ color: "#bf80ff" }} />
            </ListItemIcon>
            <ListItemText>Add Places</ListItemText>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className="menu">
        <Link className="link" to="/hotels">
          <ListItem>
            <ListItemIcon>
              <Business style={{ color: "skyblue" }} />
            </ListItemIcon>
            <ListItemText>Hotels</ListItemText>
          </ListItem>
        </Link>
        <Link className="link" to="/addhotels">
          <ListItem>
            <ListItemIcon>
              <AddCircleOutline style={{ color: "skyblue" }} />
            </ListItemIcon>
            <ListItemText>Add Hotels</ListItemText>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className="menu">
        <Link className="link" to="/agency">
          <ListItem>
            <ListItemIcon>
              <DoubleArrow style={{ color: "#ffcc00" }} />
            </ListItemIcon>
            <ListItemText>Travel Agency</ListItemText>
          </ListItem>
        </Link>
        <Link className="link" to="/addagency">
          <ListItem>
            <ListItemIcon>
              <AddCircleOutline style={{ color: "#ffcc00" }} />
            </ListItemIcon>
            <ListItemText>Add Agency</ListItemText>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className="menu">
        <Link className="link" to="/category">
          <ListItem>
            <ListItemIcon>
              <Category style={{ color: "lightgreen" }} />
            </ListItemIcon>
            <ListItemText>Category</ListItemText>
          </ListItem>
        </Link>
        <Link className="link" to="/addcategory">
          <ListItem>
            <ListItemIcon>
              <AddCircleOutline style={{ color: "lightgreen" }} />
            </ListItemIcon>
            <ListItemText>Add Category</ListItemText>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className="menu">
        <Link className="link" to="/gallery">
          <ListItem>
            <ListItemIcon>
              <Collections style={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText>Gallery</ListItemText>
          </ListItem>
        </Link>
        <Link className="link" to="/addgallery">
          <ListItem>
            <ListItemIcon>
              <AddCircleOutline style={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText>Add Gallery</ListItemText>
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className="menu">
        <Link className="link" to="/images">
          <ListItem>
            <ListItemIcon>
              <Collections style={{ color: "#3399ff" }} />
            </ListItemIcon>
            <ListItemText>Images</ListItemText>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ backgroundColor: "#2196c4" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            Hi, {localStorage.getItem("name")}
          </Typography>
          <Typography
            style={{ margin: "0 0 0 auto", cursor: "pointer" }}
            variant="h6"
            noWrap
            onClick={() => {
              if (window.confirm("Are you sure?")) {
                localStorage.removeItem("token");
                props.history.push("/");
              } else {
                alert("cancelled");
              }
            }}
          >
            Logout
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <MyHidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </MyHidden>
        <MyHidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </MyHidden>
      </nav>
      <main
        className={classes.content}
        style={{ backgroundColor: "#fafafa", height: "auto" }}
      >
        <div className={classes.toolbar} />
        <Suspense fallback={<div>Loading...</div>}>
          <Component {...props} />
        </Suspense>
      </main>
    </div>
  );
}
